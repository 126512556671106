import React from 'react'
import MaterialLinkCard from '../MaterialLinkCard'
import FooterDashboard from '../../FooterDashboard/FooterDashboard'

const StudyMaterialMain = () => {
  return (
    <div style={{width:'100%'}} className='studymaterialmain-section'>
        <MaterialLinkCard />
    </div>
  )
}

export default StudyMaterialMain
