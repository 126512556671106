import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { styled } from '@mui/material/styles';

const OverlayIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontSize: '48px',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}));

const MaterialCard = ({material}) => {
  return (
    <Card 
      sx={{ 
        maxWidth: 345, 
        cursor: 'pointer', 
        position: 'relative',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': { 
          transform: 'scale(1.05)', 
          boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
        '&:hover .play-icon': {
          opacity: 1,
        }
      }}
      onClick={() => window.open(material.url, '_blank')}
    >
      <CardMedia
        component="img"
        height="180"
        image={material.thumbnail}
        alt={material.title}
      />
      <OverlayIconButton className="play-icon">
        <PlayCircleOutlineIcon fontSize="inherit" />
      </OverlayIconButton>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {material.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {material.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MaterialCard;
