import React from "react";
import "./AssignmentCard.css";
import { useNavigate } from "react-router-dom";

function AssignmentCard({ assignment }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/assignmentdetails", { state: { assignment: assignment } });
  };

  const date = assignment?.submission_deadline;
  const dueDate = new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-');

  return (
    <div className="assignmentcard-section">
      <div className="assignmentcard-container">
        <div className="assignmentcard-header">
          <p className="assignment-name"> {assignment?.assignment_title}</p>
          <p className="assignment-date">
            Deadline: <span>{dueDate}</span>
          </p>
        </div>
        <div className="assignment-view-btn">
          <button onClick={handleClick}>View Details</button>
        </div>
      </div>
    </div>
  );
}

export default AssignmentCard;
