import React, { useEffect, useState } from "react";
import "./StudentMainSection.css";
import StudentAnimatedBackground from "../StudentAnimatedBackground/StudentAnimatedBackground";
import { getCurrentUser } from "../../services/restApiAuth";
import CalendarComponent from "../CalendarComponent/CalendarComponent";
import EnrolledCourse from "../EnrolledCourse/EnrolledCourse";
import AssignedTrainerCard from "../AssignedTrainerCard/AssignedTrainerCard";
import AttendanceGraph from "../AttendanceGraph/AttendanceGraph";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import { useQuery } from "@tanstack/react-query";

const StudentMainSection = () => {
  const navigate = useNavigate();

  const {
    isLoading,
    data: userData,
    error,
  } = useQuery({
    queryKey: ["currUser"],
    queryFn: () => getCurrentUser(),
    retry: true,
    // refetchInterval: 30 * 1000,
  });
  // const userData = await getCurrentUser();
  const username = userData?.user?.name;

  const next24Hours = new Date() + 24 * 60 * 60 * 1000;
  const now = new Date().getTime();

  //1) FILTERING SCHEDULES BASED ON is_booked= true
  let bookedSchedules = userData?.user?.classes?.filter((s) => s.is_booked);

  //2_ FILTER THE UPCOMING MEETINGS FROM LAST 30 MIN FROM NOW TILL --
  let newScheduleArray = [];
  newScheduleArray = bookedSchedules
    ? bookedSchedules
        ?.filter((classItem) => {
          let startTime;
          const date = new Date();

          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
          // console.log(formattedDate);
          // 3) CHANGE START DATE TO TODAY'S DATE UNTILL END DATE IS PASSED.(FOR RECURRING MEETINGS)
          if (new Date().getTime() < new Date(classItem.end_date).getTime()) {
            startTime = `${formattedDate}T${classItem.start_time}`;
          }

          const meetingStartTime = new Date(startTime).getTime();
          // console.log(
          //   meetingStartTime >= now - 0.5 * 60 * 60 * 1000,
          //   "meetingStartTime"
          // );
          return meetingStartTime >= now - 30 * 60 * 1000;
        })
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
    : [];

  console.log(newScheduleArray, "newScheduleArray");

  function handleProfileClick() {
    navigate("/profile");
  }

  return (
    <>
      {!isLoading && (
        <div className="student-main-section">
          <div className="student-main-container">
            <div className="heading-bell-container">
              <p className="student-welcome-heading">Welcome! {username}</p>
              <div className="profile-notification-container">
                <p className="bell-icon material-symbols-outlined">
                  notifications
                </p>
                <ProfileIcon />
              </div>
            </div>
            <div className="student-upcoming-events">
              <StudentAnimatedBackground nearestClass={newScheduleArray[0]} />
            </div>
            <div className="Slot-calendar-section">
              <div className="calendar-and-table">
                <div className="Basic-section">
                  <div className="basic-section-content">
                    <EnrolledCourse enrolledCourse={newScheduleArray[0]} />
                    <AssignedTrainerCard trainerName={newScheduleArray[0]} />
                  </div>
                  <div className="attendance-circle-graph">
                    <AttendanceGraph />
                  </div>
                </div>
                <div className="calendar-section">
                  <CalendarComponent upcomingClasses={bookedSchedules} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentMainSection;
