import { API_BASE_URL } from "../../services/constant";

export async function getStudentAssignments({ studentId }) {
  try {
    //
    const uri = `${API_BASE_URL}assignments/getStudentAssignments/${studentId}`;
    const studentAssignment = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await studentAssignment.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error(error.message);
  }
}

export async function submitAssignment({ assigmentId, submited_link }) {
  try {
    //1) get URL to submit assignment.
    const url = `${API_BASE_URL}assignments/submitAssignment/${assigmentId}`;
    const fetchAdminLogin = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ submited_link }),
      credentials: "include", // Send cookies along with the request
    });

    const data = await fetchAdminLogin.json();

    return data;
  } catch (err) {
    //
    console.log(err);
    throw new Error("Invalid Assignment?");
  }
}
