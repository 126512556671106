import { useMutation } from "@tanstack/react-query";
import { submitAssignment } from "../AssignmentMain/apiAssignment";
import toast from "react-hot-toast";

export function useSubmitAssignment() {
  const { mutate: subAssignment, isPending } = useMutation({
    mutationFn: ({ assigmentId, submited_link }) =>
      submitAssignment({ assigmentId, submited_link }),
    onSuccess: () => {
      // toast.success("Assignment submited successfully");

      alert("Assignment submited successfully");
    },
  });

  return { subAssignment, isPending };
}
