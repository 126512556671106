import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./ProfileSection.css";
import Swal from "sweetalert2";
import ResetPassword from "./ResetPassword";
import { getCurrentUser } from "../../services/restApiAuth";

Modal.setAppElement("#root");

const ProfileSection = () => {
  const [upcomingMeeting, setUpcomingMeeting] = useState(null);

  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getCurrentUser();
        setUsername(userData.user.name);
        setRole(userData.user.role);
        
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    userName: "John Doe",
    role: "Trainer",
    phone: "+123456789",
    email: "johndoe@example.com",
    address: "123 Main St, City, Country",
    occupation: "Teacher",
    dateOfBirth: "1990-01-01",
    facebook: "https://facebook.com",
    twitter: "https://twitter.com",
    linkedin: "https://linkedin.com",
    instagram: "https://instagram.com",
    profileImage: null,
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.userName) newErrors.userName = "User Name is required";
    if (!formData.email.includes("@")) newErrors.email = "Invalid email";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.address) newErrors.address = "Address is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (!validateForm()) return;

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setFormData({ ...formData });
        setShowModal(false);
        Swal.fire("Saved!", "Your profile has been updated.", "success");
      }
    });
  };

  return (
    <div className="profile-container">
      <div className="profile-banner">
        <div className="banner-background"></div>

        <div className="user-info">
          <div className="user-info-detail">
            <h2 className="user-name">Hello, {username}</h2>
            <p className="user-role">{role}</p>
          </div>
        </div>
        {/* <div className="edit-icon" onClick={() => setShowModal(true)}>
          <FaEdit size={24} />
        </div> */}
        {/* <div className="social-media-icons">
          <a href={formData.facebook} target="_blank" rel="noopener noreferrer">
            <FaFacebook size={20} />
          </a>
          <a href={formData.twitter} target="_blank" rel="noopener noreferrer">
            <FaTwitter size={20} />
          </a>
          <a href={formData.linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={20} />
          </a>
          <a
            href={formData.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={20} />
          </a>
        </div> */}
      </div>

      <div className="details-section">
        <div className="stats-section">
          <div className="first-row">

            <div  className="small-card">
              <h2 className="card-heading">Courses Assigned</h2>
              <p className="card-paragraph">2</p>
            </div>
            <div  className="small-card">
              <h2 className="card-heading">Trainer Assigned</h2>
              <p className="card-paragraph">{upcomingMeeting}</p>
            </div>
          </div>
          <div className="second-row">

            <div  className="small-card">
              <h2 className="card-heading">Classes Held</h2>
              <p className="card-paragraph">0</p>
            </div>
            <div className="small-card">
              <h2 className="card-heading">Performance Rating</h2>
              <p className="card-paragraph">Not Rated</p>
            </div>
          </div>
        </div>
        <ResetPassword />
      </div>
    </div>
  );
};

export default ProfileSection;
