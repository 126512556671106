import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./AssignmentInnerDetailsMain.css";
import { useSubmitAssignment } from "./useSubmitAssignment";

function AssignmentInnerDetailsMain() {
  const [fileLink, setFileLink] = useState("");

  const { subAssignment, isPending } = useSubmitAssignment();

  const location = useLocation();
  const { assignment } = location?.state || {};

  // Function to handle link input change
  const handleLinkChange = (event) => {
    setFileLink(event.target.value);
  };

  // Function to handle upload button click
  const handleUploadClick = () => {
    if (!fileLink) {
      alert("Please enter a valid file link before submitting.");
      return;
    }
    subAssignment({ assigmentId: assignment?._id, submited_link: fileLink });

    console.log("File link uploaded:", fileLink, isPending);
    // alert("File link submitted successfully!");
  };

  return (
    <div className="assignment-inner-details-main">
      {assignment?.submited ? (
        <p style={{ color: "darkred" }}>Assignment is submitted!</p>
      ) : (
        ""
      )}
      <div className="assignment-heading-upload-btn">
        <p className="assignment-inner-heading">
          {assignment?.assignment_description || "Assignment Description"}
        </p>
        <button
          className="assignment-upload-btn"
          onClick={handleUploadClick}
          disabled={assignment?.submited}
        >
          Submit
        </button>
      </div>

      <div className="assignment-inner-details-explanation">
        <p>{assignment?.assignment_title || "Assignment Title"}</p>
        <ul>
          <li>Provide a valid URL to your assignment</li>
          <li>Accepted formats: PDF, Image, Video links</li>
        </ul>
      </div>

      <form className="assignment-upload-form">
        <label htmlFor="file-link-input">
          Enter your assignment file link:
        </label>
        <input
          type="url"
          id="file-link-input"
          placeholder="https://example.com/your-file.pdf"
          value={fileLink}
          onChange={handleLinkChange}
          required
        />
        <p className="upload-instructions">
          Ensure the link is accessible and points to a valid file.
        </p>
      </form>
    </div>
  );
}

export default AssignmentInnerDetailsMain;
