import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-main.png";
import "./SideBar.css";
import React from "react";
import { useLogout } from "../../authentication/useLogout";

const navItems = [
  { name: "dashboard", displayName: "Home", route: "/studenthome" },
  { name: "library_books", displayName: "Study Material", route: "/studymaterial" },
  // { name: "leaderboard", displayName: "Ranking", route: "/leaderboard" },
  { name: "assignment", displayName: "Assignments", route: "/assignment" },
  // { name: "forum", displayName: "Discussion Forum", route: "/discussion" },
  { name: "person", displayName: "Profile", route: "/profile" },
  { name: "logout", displayName: "Logout", route: "/" }
];

export const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout: userLogout, isLoading } = useLogout(); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleLinkClick = (item) => {
    if (item.name === "logout") {
      userLogout();
    }
    handleMobileMenuToggle(); // Close the menu on any link click
  };

  return (
    <section className="page sidebar-2-page">
      {/* PC/Laptop Sidebar */}
      <aside className={`sidebar-2 ${isOpen ? "open" : ""}`}>
        <div className="inner">
          <header>
            <button
              type="button"
              className="sidebar-2-burger sidebar-btn"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="material-symbols-outlined">
                {isOpen ? "close" : "menu"}
              </span>
            </button>
            <img src={logo} width="115" height="50" alt="logo" />
          </header>
          <nav>
            {navItems.map((item) => (
              <Link
                to={item.route}
                key={item.name}
                onClick={item.name === "logout" ? userLogout : null} // Logout only for 'logout'
                className="sidebar-btn"
              >
                <span className="material-symbols-outlined">{item.name}</span>
                <p>{item.displayName}</p> {/* Custom display names */}
              </Link>
            ))}
          </nav>
        </div>
      </aside>

      {/* Mobile Sidebar */}
      <div className="mobile-menu">
        <button
          type="button"
          className="mobile-menu-burger"
          onClick={handleMobileMenuToggle}
        >
          <span className="material-symbols-outlined">
            {isMobileMenuOpen ? "close" : "menu"}
          </span>
        </button>
        {isMobileMenuOpen && (
          <aside className="mobile-sidebar">
            <nav>
              {navItems.map((item) => (
                <Link
                  to={item.route}
                  key={item.name}
                  onClick={() => handleLinkClick(item)}
                  className="sidebar-btn" // Close menu on link click
                >
                  <span className="material-symbols-outlined">{item.name}</span>
                  <p>{item.displayName}</p>
                </Link>
              ))}
            </nav>
          </aside>
        )}
      </div>
    </section>
  );
};
