import React, { useState, useEffect } from 'react';
import './LoginSigninForm.css';
import { useLogin } from './useLogin';
import { useSignup } from './useSignup';
import OtpModal from '../Otp/OtpModal';
import SpinnerMini from '../Spinner/SpinnerMini';
import { SignLanguage } from '@mui/icons-material';

function LoginSigninForm({ closePopup }) {
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const { login, isLoading } = useLogin();
    const { signup, isLoading: isSignup, isModalOpen, handleOtpSubmit, closeModal } = useSignup();

    useEffect(() => {
        const signUpButton = document.getElementById('signUp');
        const signInButton = document.getElementById('signIn');
        const container = document.querySelector('.login-form-container');

        const handleSignUpClick = () => {
            container.classList.add("right-panel-active");
        };

        const handleSignInClick = () => {
            container.classList.remove("right-panel-active");
        };

        signUpButton.addEventListener('click', handleSignUpClick);
        signInButton.addEventListener('click', handleSignInClick);

        // Cleanup the event listeners on component unmount
        return () => {
            signUpButton.removeEventListener('click', handleSignUpClick);
            signInButton.removeEventListener('click', handleSignInClick);
        };
    }, []);

    const toggleMobileView = () => {
        setIsSignUp(!isSignUp);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    function handleSubmit(e) {
        e.preventDefault();
        if (!email || !password) return;
        login({ email, password });
    }

    function handleRegistration() {
        signup({ name, email, password });
    }

    return (
        <div className='login-form-section'>
            <div className="login-form-container">
                <button className="close-btn" onClick={closePopup}>&times;</button>

                {/* Sign In Form */}
                <div className={`form-container sign-in-container ${isSignUp ? 'mobile-hidden' : ''}`}>
                    <form>
                        <p className='form-heading' style={{ marginBottom: '0.5em' }}>Sign in</p>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />

                        <div className="password-input-container">
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                            />
                            <i
                                className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                onClick={togglePasswordVisibility}
                            />
                        </div>

                        <a href="#">Forgot your password?</a>
                        <button className='login-btn' onClick={handleSubmit} disabled={isLoading}>
                            {isLoading ? (
                                <SpinnerMini />
                            ) : (
                                <p>Sign In</p>
                            )}
                        </button>
                        <button type="button" className="mobile-toggle-btn" onClick={toggleMobileView}>Sign Up</button>
                    </form>
                </div>

                {/* Sign Up Form */}
                <div className={`form-container sign-up-container ${!isSignUp ? 'mobile-hidden' : ''}`}>
                    <form>
                        <p className='form-heading' style={{ marginBottom: '0.5em' }}>Create Account</p>
                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" />
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />

                        {/* Password Input with Eye Icon */}
                        <div className="password-input-container">
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                            />
                            <i
                                className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                onClick={togglePasswordVisibility}
                            />
                        </div>

                        <button onClick={handleRegistration} type="button">Sign Up</button>
                        <OtpModal isOpen={isModalOpen} onRequestClose={closeModal} onSubmit={handleOtpSubmit} />
                        <button type="button" className="mobile-toggle-btn" onClick={toggleMobileView}>Sign In</button>
                    </form>
                </div>

                <div className="overlay-container-form">
                    <div className="overlay-form">
                        <div className="overlay-panel overlay-left">
                            <p className='form-heading'>Welcome Back!</p>
                            <p className='form-p'>To keep connected with us please login with your personal info</p>
                            <button className="ghost" id="signIn" type="button">Sign In</button>
                        </div>
                        <div className="overlay-panel overlay-right">
                            <p className='form-heading'>Hello, Friend!</p>
                            <p className='form-p'>Enter your personal details and start your journey with us</p>
                            <button className="ghost" id="signUp" type="button">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginSigninForm;
